import "./App.css";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "../src/Components/Home";
import Locations from "./pages/Locations";
import Services from "./pages/Services";
import Trackpackage from "./pages/Trackpackage";
import Review from "./pages/Review";
import Contact from "./pages/Contact";
import Header from "./pages/Header";
import Trackparcel from "./pages/Trackparcel";
import ImageWithText from "./pages/imgaewithtext";
import Footer from "./pages/Footer";
import ShipmentTracking from "./pages/ShipmentTracking";
import LogisticsSection from "./pages/LogisticsSection";
import ShippingServices from "./pages/Shipping_services";
import FacilitiesSection from "./pages/ContainerFacilities";
import Counter from "./pages/Counter";
import HomePage from "./pages/HomePage";
import Tracking from "./pages/Tracking";


function App() {
  return (
    <Router>
      <Header />     
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/location" element={<Locations />} />
        <Route path="/calculator" element={<Services />} />
        <Route path="/trackpackage" element={<Trackpackage />} />
        <Route path="/review" element={<Review />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/trackparcel" element={<Trackparcel />} />
        <Route path="/order_tracking" element={<Tracking />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
