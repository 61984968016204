import React from "react";

const Timeline = () => {
  const stats = [
    {
      icon: "📦",
      value: "3 Bn",
      description: "Parcels shipped since inception",
    },
    {
      icon: "🏢",
      value: "99.5%",
      description: "Indian population covered",
    },
    {
      icon: "👥",
      value: "33K+",
      description: "Businesses served",
    },
    {
      icon: "🚚",
      value: "4.3 Mn+",
      description: "Tonnes Freight shipped",
    },
    {
      icon: "🏭",
      value: "18.8 Mn+",
      description: "Square feet logistics infrastructure covered",
    },
  ];

  return (
    <div className="bg-white py-12 px-6">
      {/* Header */}
      <div className="text-center mb-12">
        <h2 className="text-lg md:text-xl font-semibold text-gray-800">
          Flexibility, Reliability and Scale
        </h2>
        <h1 className="text-2xl md:text-4xl font-bold text-gray-900">
          The Answer is Delhivery
        </h1>
        <div className="w-24 h-1 bg-red-600 mx-auto mt-2"></div>
      </div>

      {/* Timeline */}
      <div className="flex items-center justify-between overflow-x-auto space-x-6">
        {stats.map((stat, index) => (
          <div
            key={index}
            className="flex flex-col items-center relative w-48 md:w-56 bg-white p-4 rounded-lg shadow-md"
          >
            {/* Icon */}
            <div className="text-5xl bg-gray-100 p-4 rounded-full shadow-inner">
              {stat.icon}
            </div>

            {/* Value */}
            <p className="mt-4 text-2xl md:text-3xl font-bold text-gray-900">
              {stat.value}
            </p>

            {/* Description */}
            <p className="mt-2 text-sm md:text-base text-gray-600 text-center">
              {stat.description}
            </p>

          </div>
        ))}
      </div>
    </div>
  );
};

export default Timeline;
