import React, { useEffect, useState } from "react";
import { CiLocationOn } from "react-icons/ci";
import { CiSearch } from "react-icons/ci";
import { TbWorld } from "react-icons/tb";
import { motion } from "framer-motion";
import bike from '../assets/HomeImages/bike.png'

const Trackpackage = () => {
  const texts = ["Delivering Your Shipment", "Reliable Logistics", "National Services"];
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [texts.length]);

  return (
    <section className="bannerImage relative bg-opacity-100">
      <div
        className="flex flex-col items-center justify-center px-4"
        style={{ height: "80vh" }}
      >
        <div className="text-center items-center justify-center">
          <motion.h3
            className="text-5xl text-white mt-5 sm:text-7xl sm:mt-0 font-semibold text-[#3e3e3e]"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <motion.div
              key={currentTextIndex}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.8 }}
            >
              {texts[currentTextIndex]}
            </motion.div>

            <motion.div
              className="flex items-center justify-center gap-2 lg:mt-4 mt-1"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1, delay: 0.5 }}
            >
              <motion.div
                className="flex items-center justify-center lg:w-16 lg:h-16 h-12 w-12 bg-orange-100 rounded-full mt-2"
                animate={{ rotate: 360 }}
                transition={{
                  repeat: Infinity,
                  repeatType: "loop",
                  duration: 2,
                  ease: "linear",
                }}
              >
                <span style={{ fontSize: "3rem", color: "#f35a0f" }}>
                  <TbWorld className="globeIcon" />
                </span>
              </motion.div>

              <motion.span
                className="text-orange-600 zoomInDown"
                initial={{ x: -50, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1, delay: 1 }}
              >
                National
              </motion.span>
            </motion.div>
          </motion.h3>
        </div>

        <div className="flex flex-wrap items-center lg:justify-left justify-center mt-6 gap-4 flexCol">
          <div className="flex items-center inputField w-72 px-4 py-2 border border-gray-600 rounded-2xl shadow-sm">
            <span className="text-gray-500 text-lg">
              <CiLocationOn className="text-black" />
            </span>
            <input
              type="text"
              placeholder="Enter pickup location"
              className=" flex-grow bg-transparent text-md outline-none text-md placeholder-black lg:px-3 lg:py-2 p-1"
            />
          </div>

          <div className="flex items-center inputField w-72 px-4 py-2 border border-gray-600 rounded-2xl shadow-sm">
            <span className="text-gray-500 text-lg">
              <CiLocationOn className="text-black" />
            </span>
            <input
              type="text"
              placeholder="Enter destination location"
              className="flex-grow bg-transparent outline-none text-md placeholder-black lg:px-3 lg:py-2 p-1"
            />
          </div>

          <button className="lg:block hidden w-14 p-1 px-8 flex items-center justify-center bg-[#fff] text-white rounded-xl shadow-lg " style={{display: "flex", border:"2px solid #f97316"}}>
            <span className="text-4xl flex" style={{display: "inline-block", color: "#000"}}>
              <CiSearch className="text-center " />
            </span>
          </button>
        </div>
      </div>
{/* 
      <motion.div
        className="bikeImage w-full"
        style={{ position: "absolute", bottom: "0" }}
        initial={{ x: 0 }}
        animate={{
          x: ["0%", "100%"],
          y: [0, 5, 0, -10, 0],
        }}
        transition={{
          repeat: Infinity,
          repeatType: "loop",
          duration: 10,
          ease: "easeInOut",
        }}
      >
        <motion.img
          src={bike}
          alt="Delivery Bike"
          style={{ width: "120px" }}
          initial={{ opacity: 1 }}
          animate={{
            y: [0, -5, 0],
          }}
          transition={{
            repeat: Infinity,
            repeatType: "loop",
            duration: 1,
            ease: "easeInOut",
          }}
        />
      </motion.div> */}
    </section>
  );
};

export default Trackpackage;
