import React from 'react'

const Review = () => {
  return (
    <div className='m-auto w-11/12'>
      Review
      Review
    </div>
  )
}

export default Review

