import React, { useEffect } from "react";
import Tanker from "../assets/HomeImages/tanker.jpg";
import { IoWifi } from "react-icons/io5";
import { MdOutlinePhotoCamera } from "react-icons/md";
import { LuHeadphones } from "react-icons/lu";

function LogisticsSection() {
    useEffect(() => {
        const WOW = require("wow.js");
        new WOW().init();
    }, []);

    return (
        <div className="flex flex-col lg:flex-row px-6 lg:px-16 lg:py-10 lg:pt-0 bg-white rounded-xl lg:gap-12 gap-4 overflow-hidden">
            <div className="lg:w-1/2 space-y-6 wow slideInLeft" data-wow-duration="1s">
                <h1 className="text-4xl font-bold leading-snug text-black text-center lg:text-left" style={{lineHeight:"1"}}>
                    Powering logistics across business
                </h1>
                <p className="text-gray-600 text-center lg:text-left">
                    Delight your customers, scale operations, and boost efficiency with
                    our advanced logistics platform. We're here to supercharge your
                    supply chain.
                </p>
                <div className="space-y-6">
                    <div className="flex items-center pb-4 border-b border-black">
                        <div className="flex items-center justify-center w-12 h-12 bg-orange-100 rounded-full">
                            <IoWifi className="text-orange-500 font-bold text-[26px]" />
                        </div>
                        <p className="ml-4 text-gray-700 font-medium">
                            Nationwide carrier network
                        </p>
                    </div>

                    <div className="flex items-center pb-4 border-b border-black">
                        <div className="flex items-center justify-center w-12 h-12 bg-orange-100 rounded-full">
                            <MdOutlinePhotoCamera className="text-orange-500 font-bold text-[26px]" />
                        </div>
                        <p className="ml-4 text-gray-700 font-medium">
                            Fully-featured logistics software
                        </p>
                    </div>

                    <div className="flex items-center pb-4 border-b border-black">
                        <div className="flex items-center justify-center w-12 h-12 bg-orange-100 rounded-full">
                            <LuHeadphones className="text-orange-500 font-bold text-[26px]" />
                        </div>
                        <p className="ml-4 text-gray-700 font-medium">
                            Exception tracing & live support
                        </p>
                    </div>
                </div>
            </div>

            <div className="w-full lg:w-1/2 mt-8 lg:mt-0 wow slideInRight" data-wow-duration="1s">
                <div
                    className="rounded-xl overflow-hidden shadow-md"
                    style={{ maxHeight: "400px", objectFit: "cover" }}
                >
                    <img
                        src={Tanker}
                        alt="Logistics Containers"
                        className="w-full h-auto rounded-xl"
                    />
                </div>
            </div>
        </div>
    );
}

export default LogisticsSection;
