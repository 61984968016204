import React, { useState } from "react";
import { motion } from "framer-motion";
import ContactImage from "../assets/HomeImage/contact.jpg";

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    description: "",
    gender: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  
    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach((field) => {
      if (!formData[field].trim()) {
        newErrors[field] = `${field.replace(/^\w/, (c) =>
          c.toUpperCase()
        )} is required`;
      }
    });

    if (!formData.firstName.trim()) {
        newErrors.firstName = "First Name is required.";
      } else if (!/^[A-Za-z\s]+$/.test(formData.firstName)) {
        newErrors.firstName = "First Name should contain only letters and spaces.";
      }
    
      if (!formData.lastName.trim()) {
        newErrors.lastName = "Last Name is required.";
      } else if (!/^[A-Za-z\s]+$/.test(formData.lastName)) {
        newErrors.lastName = "Last Name should contain only letters and spaces.";
      }

    
    if (
      formData.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)
    ) {
      newErrors.email = "Invalid email address";
    }

    
    if (formData.phone && !/^\d{10,}$/.test(formData.phone)) {
      newErrors.phone = "Invalid phone number (at least 10 digits)";
    }

    if (!formData.message.trim()) newErrors.message = "Message is required.";
    if (!formData.description.trim()) newErrors.description = "Description is required.";
    if (!formData.gender) newErrors.gender = "Gender selection is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    console.log("Form Data Submitted: ", formData);
    alert("Thank you for contacting us!");
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
      description: "",
      gender: "",
    });
    setErrors({})
  };

  return (
    <div className="min-h-screen flex items-center justify-center my-12 ">
      <div className="container mx-auto flex flex-col gap-6 md:flex-row p-8 shadow-md  w-11/12 bg-white border" style={{borderRadius: "20px"}}>
        
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
          className="w-full md:w-1/2"
        >
          <img
            src={ContactImage}
            alt="Contact Us"
            className="w-full h-full object-cover rounded-l-md"
          />
        </motion.div>

       
        <motion.div
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
          className="w-full md:w-1/2 p-1"
        >
          <h2 className="text-4xl font-bold text-gray-800 mb-4 text-center">
            Get in Touch
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
             
              <div className="flex flex-col">
                <label
                  htmlFor="firstName"
                  className="text-sm font-semibold text-gray-600 mb-1"
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="Enter your first name"
                  className={`border ${
                    errors.firstName
                      ? "border-red-500 focus:ring-red-500"
                      : "border-gray-300 focus:ring-orange-500"
                  } rounded-md p-2 focus:outline-none focus:ring-2`}
                />
                {errors.firstName && (
                  <span className="text-red-500 text-sm">{errors.firstName}</span>
                )}
              </div>
           
              <div className="flex flex-col">
                <label
                  htmlFor="lastName"
                  className="text-sm font-semibold text-gray-600 mb-1"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Enter your last name"
                  className={`border ${
                    errors.lastName
                      ? "border-red-500 focus:ring-red-500"
                      : "border-gray-300 focus:ring-orange-500"
                  } rounded-md p-2 focus:outline-none focus:ring-2`}
                />
                {errors.lastName && (
                  <span className="text-red-500 text-sm">{errors.lastName}</span>
                )}
              </div>
            </div>

            <div className="flex flex-col mt-4">
              <label
                htmlFor="email"
                className="text-sm font-semibold text-gray-600 mb-1"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                className={`border ${
                  errors.email
                    ? "border-red-500 focus:ring-red-500"
                    : "border-gray-300 focus:ring-orange-500"
                } rounded-md p-2 focus:outline-none focus:ring-2`}
              />
              {errors.email && (
                <span className="text-red-500 text-sm">{errors.email}</span>
              )}
            </div>

           
            <div className="flex flex-col mt-4">
              <label
                htmlFor="phone"
                className="text-sm font-semibold text-gray-600 mb-1"
              >
                Phone
              </label>
              <input
                onWheel={(e) => e.target.blur()} 
                type="number"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Enter your phone number"
                className={`border ${
                  errors.phone
                    ? "border-red-500 focus:ring-red-500"
                    : "border-gray-300 focus:ring-orange-500"
                } rounded-md p-2 focus:outline-none focus:ring-2`}
              />
              {errors.phone && (
                <span className="text-red-500 text-sm">{errors.phone}</span>
              )}
            </div>

              
                <div className="flex flex-col mt-4">
              <label htmlFor="message" className="text-sm font-semibold text-gray-600 mb-1">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Write your message here"
                rows="3"
                className={`border rounded-md p-2 focus:outline-none focus:ring-2 ${
                  errors.message  ? "border-red-500 focus:ring-red-500"
                    : "border-gray-300 focus:ring-orange-500"
                }`}
              />
              {errors.message && <span className="text-red-500 text-sm">{errors.message}</span>}
            </div>

          
            <div className="flex flex-col mt-4">
              <label htmlFor="description" className="text-sm font-semibold text-gray-600 mb-1">
                Description
              </label>
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder="Add a description"
                rows="3"
                className={`border rounded-md p-2 focus:outline-none focus:ring-2 ${
                  errors.description  ? "border-red-500 focus:ring-red-500"
                    : "border-gray-300 focus:ring-orange-500"
                }`}
              />
              {errors.description && <span className="text-red-500 text-sm">{errors.description}</span>}
            </div>

            
            <div className="flex flex-col mt-4">
              <label htmlFor="gender" className="text-sm font-semibold text-gray-600 mb-1">
                Gender
              </label>
              <select
                id="gender"
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                className={`border rounded-md p-2 focus:outline-none focus:ring-2 ${
                  errors.gender  ? "border-red-500 focus:ring-red-500"
                    : "border-gray-300 focus:ring-orange-500"
                }`}
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
              {errors.gender && <span className="text-red-500 text-sm">{errors.gender}</span>}
            </div>

            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              type="submit"
              className="w-full bg-orange-500 text-white font-semibold text-lg py-2 rounded-md mt-6 shadow-md    focus:ring-2 focus:ring-orange-400"
            >
              Submit
            </motion.button>
          </form>
        </motion.div>
      </div>
    </div>
  );
};

export default Contact;
