import React, { useEffect } from "react";

const Trackparcel = () => {

  useEffect(() => {
    const WOW = require("wow.js");
    new WOW().init();
  }, []);

  return (
    <section className="lg:py-16 lg:pt-0 py-0 mb-12 lg:mb-0 flex flex-col justify-center items-center relative">
      <div className="text-center z-10">
        <h1 className="text-4xl md:text-6xl font-semibold text-gray-800 leading-tight wow zoomInDown">
          Let Us Deliver Your Package
        </h1>
        <h1 className="text-4xl md:text-6xl font-semibold text-gray-800 leading-tight wow zoomInDown">
          To Its Destination
        </h1>
      </div>
      <div className="mt-6 flex gap-4 z-10 wow zoomInDown">
        <button className="bg-orange-500 text-white px-7 py-2 rounded-lg font-semibold hover:bg-orange-600 transition duration-300">
          Track Parcel
        </button>
        <button className="border border-gray-700 text-gray-800 px-7 py-2 rounded-lg font-semibold hover:bg-gray-100 transition duration-300">
          Sign Up
        </button>
      </div>
    </section>
  );
};

export default Trackparcel;
