import React, { useEffect } from 'react';
import WOW from 'wow.js';
import img from '../assets/HomeImages/1img.png';
import ship from '../assets/HomeImages/2img.png';
import img1 from '../assets/HomeImages/1.png';
import img2 from '../assets/HomeImages/2.png';
import img3 from '../assets/HomeImages/3.png';
import img4 from '../assets/HomeImages/4.png';
import img5 from '../assets/HomeImages/5.png';
import img6 from '../assets/HomeImages/6.png';
import { useNavigate } from 'react-router-dom';

const ImageWithText = () => {
    const navigate = useNavigate()
    useEffect(() => {
        const wow = new WOW();
        wow.init();
    }, []);

 const redirect = () =>{
   navigate('/contact')
 }

    return (
        <div className="bg-gray-900 text-white lg:p-10 p-8">
            <div className="flex justify-center space-x-12 mb-8 dNone">
                <img src={img1} alt="Oxfam" className="h-6" />
                <img src={img2} alt="DT Global" className="h-6" />
                <img src={img3} alt="Nayba" className="h-6" />
                <img src={img4} alt="Move" className="h-6" />
                <img src={img5} alt="Winsupply" className="h-6" />
                <img src={img6} alt="Ferguson" className="h-6" />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:gap-16 gap-8 p-0 items-center lg:p-12">
                <div className="space-y-4 wow slideInRight">
                    <img src={img} alt="Containers" className="rounded-xl" />
                </div>

                <div className="wow slideInLeft">
                    <div className="flex gap-2">
                        <h1 className="lg:text-4xl text-2xl font-bold text-orange-500 mb-4">#1</h1>
                        <h1 className="lg:text-4xl text-2xl"> Nationwide Delivery Logistics Solution</h1>
                    </div>
                    <p className="text-gray-300 mb-6 mt-4 text-start">
                        It is a Swiss international cargo company established in the year 1970.
                        With a buying spree of new and old vessels, MSC added almost 100 ships to
                        its fleet in the last year, having a vessel line-up of over 645 container
                        ships.
                    </p>
                    <div className="flex space-x-4">
                        <button className="bg-orange-500 button button-2 text-white px-6 py-3 rounded-lg font-semibold hover:bg-orange-600" onClick={redirect}>
                            Get a Quote
                        </button>
                        <button className="bg-gray-800 text-white px-6 border py-3 rounded-lg font-semibold hover:bg-gray-700">
                            Learn More
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImageWithText;
