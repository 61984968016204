import React from 'react'
import Trackpackage from './Trackpackage'
import ImageWithText from './imgaewithtext'
import Counter from './Counter'
import ShippingServices from './Shipping_services'
import LogisticsSection from './LogisticsSection'
import ShipmentTracking from './ShipmentTracking'
import FacilitiesSection from './ContainerFacilities'
import Trackparcel from './Trackparcel'

function HomePage() {
    return (
        <div>
            <Trackpackage />
            <ImageWithText />
            <Counter />
            <ShippingServices />
            <LogisticsSection />
            <ShipmentTracking />
            <FacilitiesSection />
            <Trackparcel />
        </div>
    )
}

export default HomePage