import React, { useEffect } from "react";
import TrackImage from '../assets/HomeImages/trackImg.png';

function ShipmentTracking() {
    useEffect(() => {
        const WOW = require("wow.js");
        new WOW().init();
    }, []);
    return (
        <div className="relative lg:px-16 px-6 rounded-2xl overflow-hidden lg:mt-5 mt-12 wow zoomIn" data-wow-duration="1s">
            <div className="relative">
                <img
                    src={TrackImage}
                    alt="Shipment"
                    className="w-full h-[500px] object-cover" style={{borderRadius: "40px"}}
                />
                <div className=" trackCalculate absolute inset-0 flex flex-col justify-center text-white" style={{ left: "80px" }}>
                    <h1 className="text-4xl font-bold mb-2">
                        Track or Calculate
                    </h1>
                    <h1 className="text-4xl font-bold mb-2">
                        your shipments
                    </h1>
                    <div className="flex subTrackCalculate items-center space-x-6 mt-4 ">
                        <button className="flex items-center text-white text-sm font-semibold">
                            Shipment Tracking
                            <div className="w-2 h-2 bg-orange-500 rounded-full ml-2"></div>
                        </button>
                        <button className="text-white-400 text-sm font-semibold">
                            Shipment Rate
                        </button>
                    </div>
                </div>
            </div>

            <div className="quickTrack1 absolute lg:right-20 right-0 text-center top-1/2 transform -translate-y-1/2 bg-white p-6 rounded-lg shadow-lg w-80">
                <h2 className="text-lg font-bold text-gray-900 mb-0">
                    Quickly Track your
                </h2>
                <h2 className="text-lg font-bold text-gray-900 mb-4">
                    Shipments
                </h2>
                <form className="space-y-4">
                    <input
                        type="text"
                        placeholder="Enter your shipment code"
                        className="w-full px-4 py-3 border border-gray-300 rounded-lg text-gray-700 focus:ring-2 focus:ring-orange-500 focus:outline-none"
                    />
                    <select
                        className="w-full px-4 py-3 border border-gray-300 rounded-lg text-gray-700 focus:ring-2 focus:ring-orange-500 focus:outline-none"
                    >
                        <option>Select Your Service</option>
                        <option>Service 1</option>
                        <option>Service 2</option>
                    </select>
                    <button
                        type="submit"
                        className="w-full bg-orange-500 text-white py-3 rounded-lg font-semibold hover:bg-orange-600 transition"
                    >
                        Track Now
                    </button>
                </form>
            </div>
            <div className="quickTrack2 flex justify-center items-center py-8 w-100 wow slideInUp">
            <div className="text-center w-100 bg-white p-8 rounded-lg shadow-lg w-160 border">
                <h2 className="text-lg font-bold text-gray-900 mb-0">
                    Quickly Track your
                </h2>
                <h2 className="text-lg font-bold text-gray-900 mb-4">
                    Shipments
                </h2>
                <form className="space-y-4">
                    <input
                        type="text"
                        placeholder="Enter your shipment code"
                        className="w-full px-4 py-3 border border-gray-300 rounded-lg text-gray-700 focus:ring-2 focus:ring-orange-500 focus:outline-none"
                    />
                    <select
                        className="w-full px-4 py-3 border border-gray-300 rounded-lg text-gray-700 focus:ring-2 focus:ring-orange-500 focus:outline-none"
                    >
                        <option>Select Your Service</option>
                        <option>Service 1</option>
                        <option>Service 2</option>
                    </select>
                    <button
                        type="submit"
                        className="w-full bg-orange-500 text-white py-3 rounded-lg font-semibold hover:bg-orange-600 transition"
                    >
                        Track Now
                    </button>
                </form>
            </div>
            </div>
        </div>
    );
}

export default ShipmentTracking;
