import React, { useState, useMemo, useRef } from "react";

const OTPModal = ({ phoneNumber, onClose }) => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [isChecked, setIsChecked] = useState(false);

  const otpRefs = useMemo(() => Array(6).fill(0).map(() => React.createRef()), []);

  const handleInputChange = (value, index) => {
    if (isNaN(value)) return;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < 5) {
      otpRefs[index + 1].current.focus();
    }
  };

  const handleBackspace = (index) => {
    const newOtp = [...otp];
    newOtp[index] = "";
    setOtp(newOtp);

    if (index > 0) {
      otpRefs[index - 1].current.focus();
    }
  };

  const isSubmitEnabled = otp.every((digit) => digit !== "") && isChecked;

  const handleSubmit = () => {
    alert(`OTP entered: ${otp.join("")}`);
    onClose(false);
  };

  return (
    <div className="fixed inset-0 z-50 flex justify-center items-center backdrop-blur-sm bg-black bg-opacity-40">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-lg font-bold mb-4">
          We have sent your One Time Password to +91 {phoneNumber}
        </h2>
        <div className="flex space-x-2 mb-4">
          {otp.map((digit, index) => (
            <input
              key={index}
              ref={otpRefs[index]}
              type="text"
              maxLength="1"
              value={digit}
              onChange={(e) => handleInputChange(e.target.value, index)}
              onKeyDown={(e) => {
                if (e.key === "Backspace") handleBackspace(index);
              }}
              className="w-10 h-10 text-center border border-gray-300 rounded"
            />
          ))}
        </div>
        <div className="mb-4">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
            />
            <span>
              I agree to{" "}
              <a href="#" className="text-blue-500">
                Privacy Policy
              </a>{" "}
              and{" "}
              <a href="#" className="text-blue-500">
                Terms and Conditions
              </a>.
            </span>
          </label>
        </div>
        <button
          onClick={handleSubmit}
          disabled={!isSubmitEnabled}
          className={`w-full py-3 rounded-lg ${
            isSubmitEnabled
              ? "bg-orange-600 text-white hover:bg-orange-700"
              : "bg-gray-300 text-gray-500 cursor-not-allowed"
          }`}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default OTPModal;
