import React, { useState, useEffect, useRef } from 'react';
import CountUp from 'react-countup';

function Counter() {
    const [isVisible, setIsVisible] = useState(false);
    const counterRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            {
                threshold: 0.5,
            }
        );

        if (counterRef.current) {
            observer.observe(counterRef.current);
        }

        return () => {
            if (counterRef.current) {
                observer.disconnect();
            }
        };
    }, []);

    return (
        <section className="container h-12">
            <div
                ref={counterRef}
                className="mx-auto lg:my-4 lg:mt-0 mt-8 grid w-full -translate-y-10 grid-cols-4 divide-x divide-slate-700 bg-white lg:p-4 p-6 shadow-lg dark:bg-gray-800 dark:text-white/70 md:max-w-[800px] md:-translate-y-16 md:p-8"
                style={{ borderRadius: '10px' }}
            >
                <div className="flex flex-col items-center justify-center ">
                    <h1 className="text-sm font-bold text-black/80 dark:text-white sm:text-lg md:text-3xl">
                        {isVisible && <CountUp end={234} suffix="+" duration={2.75} />}
                    </h1>
                    <h1 className="sm:text-md text-xs md:text-lg">Clients</h1>
                </div>
                <div className="flex  flex-col items-center justify-center">
                    <h1 className="text-sm font-bold text-black/80 dark:text-white sm:text-lg md:text-3xl">
                        {isVisible && <CountUp end={56} suffix="+" duration={2.75} />}
                    </h1>
                    <h1 className=" sm:text-md text-xs md:text-lg">projects</h1>
                </div>
                <div className="flex flex-col items-center justify-center">
                    <h1 className="text-sm font-bold text-black/80 dark:text-white sm:text-lg md:text-3xl">
                        {isVisible && <CountUp end={234} suffix="k+" />}
                    </h1>
                    <h1 className="sm:text-md text-xs md:text-lg">Subscribers</h1>
                </div>
                <div className="flex flex-col items-center justify-center">
                    <h1 className="text-sm font-bold text-black/80 dark:text-white sm:text-lg md:text-3xl">
                        {isVisible && (
                            <CountUp
                                start={-875.039}
                                end={160527.012}
                                duration={2.75}
                                separator=" "
                                suffix="+"
                            />
                        )}
                    </h1>
                    <h1 className="sm:text-md text-xs md:text-lg">Clients</h1>
                </div>
            </div>
        </section>
    );
}

export default Counter;
