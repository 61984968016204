import React, { useState, useEffect, useRef } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import { useNavigate, useLocation, Link } from "react-router-dom";
import gsap from "gsap";
import WOW from 'wow.js';
import logo from "../assets/HomeImages/qwikco1.png";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const headerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const links = [
    { path: "/order_tracking", label: "Tracking" },
    { path: "/calculator", label: "Calculator" },
    // { path: "/location", label: "Location" },
    // { path: "/review", label: "Review" },
    { path: "/contact", label: "Contact" },
  ];

  let lastScrollY = 0;
  useEffect(() => {
    const wow = new WOW();
    wow.init();
  }, []);

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [menuOpen]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > 400) {
        if (currentScrollY > lastScrollY) {
          gsap.to(headerRef.current, {
            y: -100,
            opacity: 0,
            duration: 0.3,
          });
          setIsSticky(false);
        } else {
          gsap.to(headerRef.current, {
            y: 0,
            opacity: 1,
            duration: 0.3,
          });
          setIsSticky(true);
        }
      } else {
        gsap.to(headerRef.current, {
          y: 0,
          opacity: 1,
          duration: 0.3,
        });
        setIsSticky(false);
      }
      lastScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleNavigation = (link) => {
    navigate(link);
    setMenuOpen(false);
  };

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      // document.body.style.overflow = "auto";
    }
  }, [menuOpen]);
  return (
    <>
      <div
        className={`header-container bg-white ${isSticky ? "sticky-header" : ""}`}
        ref={headerRef}
        style={{ position: "sticky", top: 0, zIndex: 50 }}
      >
        <header className="flex justify-between items-center lg:py-2 py-4 w-11/12 bg-white m-auto wow fadeInDown">
          <Link to="/">
            <div
              className="text-2xl font-bold text-gray-800"
              style={{ width: "180px" }}
            >
              <img src={logo} alt="banner logo" className="w-full" />
            </div>
          </Link>
          <div className="md:hidden">
            <button
              onClick={() => setMenuOpen(!menuOpen)}
              className="text-2xl text-gray-800"
            >
              {menuOpen ? <FiX /> : <FiMenu />}
            </button>
          </div>

          <nav
            className={`${menuOpen ? "block h-screen" : "hidden"} flex absolute md:static top-16 left-0 w-full md:w-auto md:flex flex-col md:flex-row items-center gap-6 bg-white md:bg-transparent z-50`  }  style={{padding:"10px", marginTop:"5px"}}
          >
            {links.map(({ path, label }) => (
              <button
                key={path}
                onClick={() => handleNavigation(path)}
                className={`px-3 py-2 shadow lg:shadow-none rounded-md text-md font-bold transition-all w-full md:w-auto text-center md:text-left ${location.pathname === path
                  ? "bg-orange-500 text-white"
                  : "text-[#3e3e3e]"
                  } hover:bg-orange-500 hover:text-white`}
              >
                {label}
              </button>
            ))}
          </nav>
        </header>
        <hr className="border-gray-300 w-11/12 m-auto" />
      </div>
    </>
  );
};

export default Header;
