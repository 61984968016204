import React from 'react'

const Locations = () => {
  return (
    <div className='m-auto w-11/12'>
      Locations
      Locations
    </div>
  )
}

export default Locations
