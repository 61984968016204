import React, { useEffect, useState } from "react";
import calculator from "../assets/HomeImages/calculatorImg.webp";

const Services = () => {
  const [paymentMode, setPaymentMode] = useState("COD");
  const [formData, setFormData] = useState({
    pickupPincode: "",
    deliveryPincode: "",
    weight: 0.5,
    length: "",
    width: "",
    height: "",
    paymentMode: "Prepaid",
    shipmentValue: 2000,
  });

  const [errors, setErrors] = useState({});

  const validate = () => {
    const validationErrors = {};
    if (!/^\d{6}$/.test(formData.pickupPincode)) {
      validationErrors.pickupPincode = "Pickup area pincode must be a 6-digit number.";
    }
    if (!/^\d{6}$/.test(formData.deliveryPincode)) {
      validationErrors.deliveryPincode = "Delivery area pincode must be a 6-digit number.";
    }
    if (formData.weight <= 0) {
      validationErrors.weight = "Weight must be greater than 0.";
    }
    if (formData.length && formData.length <= 0) {
      validationErrors.length = "Length must be a positive number.";
    }
    if (formData.width && formData.width <= 0) {
      validationErrors.width = "Width must be a positive number.";
    }
    if (formData.height && formData.height <= 0) {
      validationErrors.height = "Height must be a positive number.";
    }
    if (formData.shipmentValue < 0) {
      validationErrors.shipmentValue = "Shipment value cannot be negative.";
    }
    return validationErrors;
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      // Perform form submission logic
      console.log("Form submitted successfully:", formData);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleReset = () => {
    setFormData({
      pickupPincode: "",
      deliveryPincode: "",
      weight: 0.5,
      dimensions: { length: "", width: "", height: "" },
      paymentMode: "Prepaid",
      shipmentValue: 2000,
    });
  };
  
  <button 
    className="px-6 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
    type="button"
    onClick={handleReset}
  >
    Reset
  </button>
  

  useEffect(() => {
    const WOW = require("wow.js");
    new WOW().init();
}, []);

  return (
    <section className="lg:px-12 px-4">
    <div className="bg-white border shadow-lg w-full max-w-12xl lg:my-12 my-8 mx-auto" style={{ borderRadius: "20px" }}>
      <div className="flex border-b">
        <button className="px-6 py-2 text-orange-600 font-semibold border-b-2 border-orange-600">
          Domestic
        </button>
      </div>

      <div className="flex flex-wrap md:flex-nowrap gap-8 items-center">
        <form
          onSubmit={handleSubmit}
          className="grid grid-cols-1 md:grid-cols-2 gap-6 p-4 md:p-8 w-full md:w-[70%]"
        >
          <div>
            <label className="block text-sm font-bold text-gray-700 mb-1">
              Pick-up Area Pincode<span className="text-red-500 font-bold">*</span>
            </label>
            <input
              type="text"
              name="pickupPincode"
              value={formData.pickupPincode}
              onChange={handleInputChange}
              placeholder="Enter 6 digit Pickup Area Pincode"
              className="w-full px-4 py-2 border rounded-lg outline-none focus:ring-2 focus:ring-orange-500"
            />
            {errors.pickupPincode && <p className="text-red-500 text-xs">{errors.pickupPincode}</p>}
          </div>

          <div>
            <label className="block text-sm font-bold text-gray-700 mb-1">
              Delivery Area Pincode<span className="text-red-500 font-bold">*</span>
            </label>
            <input
              type="text"
              name="deliveryPincode"
              value={formData.deliveryPincode}
              onChange={handleInputChange}
              placeholder="Enter 6 digit Delivery Area Pincode"
              className="w-full px-4 py-2 border rounded-lg outline-none focus:ring-2 focus:ring-orange-500"
            />
            {errors.deliveryPincode && <p className="text-red-500 text-xs">{errors.deliveryPincode}</p>}
          </div>

          <div>
            <label className="block text-sm font-bold text-gray-700 mb-1">
              Weight<span className="text-red-500 font-bold">*</span>
            </label>
            <div className="flex">
              <input
                type="number"
                name="weight"
                value={formData.weight}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border outline-none rounded-l-lg focus:ring-2 focus:ring-orange-500"
              />
              <span className="px-4 py-2 bg-gray-100 border rounded-r-lg">KG</span>
            </div>
            {errors.weight && <p className="text-red-500 text-xs">{errors.weight}</p>}
          </div>

          <div>
            <label className="block text-sm font-bold text-gray-700 mb-1">
              Dimensions (Optional)
            </label>
            <div className="flex gap-2">
              <input
                type="number"
                name="length"
                value={formData.length}
                onChange={handleInputChange}
                placeholder="Length"
                className="w-full px-4 py-2 border rounded-lg outline-none focus:ring-2 focus:ring-orange-500"
              />
              <input
                type="number"
                name="width"
                value={formData.width}
                onChange={handleInputChange}
                placeholder="Width"
                className="w-full px-4 py-2 border rounded-lg outline-none focus:ring-2 focus:ring-orange-500"
              />
              <input
                type="number"
                name="height"
                value={formData.height}
                onChange={handleInputChange}
                placeholder="Height"
                className="w-full px-4 py-2 border outline-none rounded-lg focus:ring-2 focus:ring-orange-500"
              />
            </div>
            {errors.length && <p className="text-red-500 text-xs">{errors.length}</p>}
            {errors.width && <p className="text-red-500 text-xs">{errors.width}</p>}
            {errors.height && <p className="text-red-500 text-xs">{errors.height}</p>}
          </div>

          <div>
            <label className="block text-sm font-bold text-gray-700 mb-1">
              Payment Mode<span className="text-red-500 font-bold">*</span>
            </label>
            <div className="flex gap-4">
              <label className="flex items-center gap-2">
                <input
                  type="radio"
                  name="paymentMode"
                  value="Prepaid"
                  checked={formData.paymentMode === "Prepaid"}
                  onChange={handleInputChange}
                  className="focus:ring-purple-500"
                />
                Prepaid
              </label>
              {/* <label className="flex items-center gap-2">
                <input
                  type="radio"
                  name="paymentMode"
                  value="COD"
                  checked={formData.paymentMode === "COD"}
                  onChange={handleInputChange}
                  className="focus:ring-purple-500"
                />
                Cash on Delivery
              </label> */}
            </div>
          </div>

          <div>
            <label className="block text-sm font-bold text-gray-700 mb-1">
              Shipment Value
            </label>
            <input
              type="number"
              name="shipmentValue"
              value={formData.shipmentValue}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border outline-none Shipment Value rounded-lg focus:ring-2 focus:ring-orange-500"
            />
            {errors.shipmentValue && <p className="text-red-500 text-xs">{errors.shipmentValue}</p>}
          </div>
          <div className="mt-2 flex gap-4">
            <button type="submit" className="px-6 py-2 bg-orange-500 text-white rounded-lg">
              Calculate
            </button>
            <button
              type="button"
              className="px-6 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
              onClick={handleReset}
            >
              Reset
            </button>
          </div>
        </form>
        <div className="hidden md:flex w-full md:w-[30%] justify-end" style={{ display: "flex" }}>
          <img src={calculator} alt="Calculator" className="w-64 md:block hidden" />
        </div>
      </div>
    </div>
  </section>

  );
};

export default Services;
