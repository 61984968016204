import React from "react";
import { motion } from "framer-motion";
import image1 from "../assets/HomeImage/40ft.webp"
import image2 from "../assets/HomeImage/80ft.jpg"
import image3 from "../assets/HomeImage/20ft.jpg"
import { PiShoppingCartSimpleFill } from "react-icons/pi"

const FacilitiesSection = () => {
  const facilities = [
    {
      image: image1,
      title: "40 Foot Container",
      price: "$2,400 /Per year",
    },
    {
      image: image2,
      title: "80 Foot Container",
      price: "$2,400 /Per year",
    },
    {
      image: image3,
      title: "20 Foot Container",
      price: "$2,400 /Per year",
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.1,
      },
    },
  };

  const headerVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 20,
      },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 20,
      },
    },
  };

  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.2 }}
      variants={containerVariants}
      className="lg:py-16 py-8 bg-white"
    >
      <motion.div
        variants={headerVariants}
        className="flex justify-between lg:flex-row flex-col items-center px-8 md:px-20"
      >
        <h2 className="lg:text-4xl lg:w-1/2 text-2xl lg:w-50 w-full mr-2 mt-2 sm:text-7xl sm:mt-0 mb-3 font-semibold text-gray-800 leading-snug">
          Explore all container <br />
          facilities
        </h2>
        
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="border btnWidth border-gray-700 px-9 py-4 rounded-lg text-gray-800 hover:bg-gray-100 transition"
        >
          Explore All
        </motion.button>
      </motion.div>

      <div className="grid grid-cols-1 md:grid-cols-3 lg:gap-8 gap-0 lg:mt-4 mt-10 px-6 md:px-20">
        {facilities.map((facility, index) => (
          <motion.div
            key={index}
            variants={cardVariants}
            whileHover={{ y: -10 }}
            className="rounded-3xl overflow-hidden bg-white relative"
          >
            <motion.div
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              className="overflow-hidden"
            >
              <img
                src={facility.image}
                alt={facility.title}
                className="h-72 w-full object-cover rounded-[45px] transition-transform duration-300"
              />
            </motion.div>

            <motion.div
              className="p-6"
              variants={cardVariants}
            >
              <h3 className="text-lg font-semibold text-gray-800">
                {facility.title}
              </h3>
              <p className="text-gray-500 text-sm">{facility.price}</p>
            </motion.div>

            <motion.div
              whileHover={{
                scale: 1.1,
                backgroundColor: "#F97316",
                color: "white"
              }}
              className="absolute bottom-6 right-6 bg-white rounded-full p-3 cursor-pointer transition-colors"
            >
              <PiShoppingCartSimpleFill className="text-2xl" />
            </motion.div>
          </motion.div>
        ))}
      </div>
    </motion.section>
  );
};

export default FacilitiesSection;
