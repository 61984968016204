import React, { useRef } from "react";
import sea from "../assets/HomeImages/sea.gif";
import train from "../assets/HomeImages/train.gif";
import plain from "../assets/HomeImages/pp.gif";
import { GoArrowDownRight } from "react-icons/go";
import { motion } from "framer-motion";
import ScrollAnimation from "../Components/ScrollAnimation";

const ShippingServices = () => {
  const services = [
    {
      title: "Sea Shipping",
      image: sea,
    },
    {
      title: "Train Shipping",
      image: train,
    },
    {
      title: "Air Shipping",
      image: plain,
    },
  ];

  return (
    <section className="bg-white lg:py-16 py-10 w-full lg:px-0 px-6">
      <ScrollAnimation>
        <h1 className="text-4xl font-semibold text-center mb-8">
          Shipping & Logistics <br /> Services
        </h1>
      </ScrollAnimation>
      <div className="w-full grid grid-cols-1 sm:grid-cols-3 gap-8 md:px-20">
        {services.map((service, index) => (
          <ScrollAnimation
            key={index}
            delay={index * 0.2}
            direction={index % 2 === 0 ? "up" : "down"}
          >
            <div
              className="relative bgImage w-full h-80 bg-cover bg-center lg:rounded-[50px] rounded-[25px] shadow-md"
              style={{
                backgroundImage: `url(${service.image})`,
              }}
            >
              <div className="absolute inset-0 bg-black bg-opacity-50 lg:rounded-[50px] bg-opacity-25 rounded-[25px] "></div>
              <div className="absolute bottom-6 left-6 text-white z-10">
                <h2 className="text-lg font-semibold">{service.title}</h2>
              </div>
              <div className="absolute bottom-6 right-6 z-10">
                <button className="w-10 h-10 rounded-full bg-white flex items-center justify-center text-black shadow-md">
                  <GoArrowDownRight className="text-2xl" />
                </button>
              </div>
            </div>
          </ScrollAnimation>
        ))}
      </div>
    </section>
  );
};

export default ShippingServices;
