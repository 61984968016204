import React from "react";
import { motion } from "framer-motion";

const ScrollAnimation = ({
  children,
  direction = "up",
  delay = 0,
  duration = 0.5,
  className = "",
  amount = 0.3,
}) => {
  const directions = {
    up: { y: 50 },
    down: { y: -50 },
    left: { x: 50 },
    right: { x: -50 },
  };

  return (
    <motion.div
      initial={{
        opacity: 0,
        ...directions[direction],
      }}
      whileInView={{
        opacity: 1,
        x: 0,
        y: 0,
        transition: {
          duration: duration,
          delay: delay,
          ease: "easeOut",
        },
      }}
      viewport={{ once: true, amount: amount }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

export default ScrollAnimation;
